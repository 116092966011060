/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, Plus, Search } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import { deleteAgreementBulk } from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import HamburgerMenu from "../HamburgerMenuAgreements";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import AgreementAnalytics1 from "../../components/AgreementAnalytics/AgreementAnalytics1";
import AgreementGraph1 from "./AgreementGraph1";
import { getAgreementsInSignedList} from "../../utilities/apis/agreements";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const AgreementsSigned = () => {
  const currentUser = useSelector(selectUserData);
  const [open, setOpen] = useState(false);
  const [listing, setListing] = useState({
    list: null,
    agreementsCount: null,
    loaded: false,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    statusType: "",
    estamp: null,
    agreementType: null,
    location: null,
    startDate: null,
    endDate: null,
  });
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [agreementPayload, setAgreementPayload] = useState({
    page: 1,
    sortByOptions: {
      createdAt: 'desc',
    },
    filterOptions: {},
  });
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [agreementId, setAgreementId] = useState(null);
  const handleSearchClick = (id) => {
    setShowModal(true);
    setAgreementId(id);
  };
  const [showModalRelated, setShowModalRelated] = useState(false);
  const [relatedAgreementId, setRelatedAgreementId] = useState(null);
  const handlePlusClick = (id) => {
    setShowModalRelated(true);
    setRelatedAgreementId(id);
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  

  const fetchAgreementsList = async ({ page }) => {
    let filterOptions = {};
    if (listing.startDate && listing.endDate) {
      filterOptions["createdAt"] = {
        gte: formatISO(listing.startDate),
        lte: formatISO(listing.endDate),
      };
    } else if (listing.startDate || listing.endDate) {
      showNotification({
        title: "Agreement notification",
        message: "please select start and end date",
        color: "red",
      });
      return;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : uiConfigs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
    };
    if (listing.agreementType && listing.statusType && listing.estamp) {
      filterOptions = {
        ...filterOptions,
        typeOfAgreement: {
          equals: listing.agreementType,
        },
        status: {
          equals: listing.statusType,
        },
        agreementType: {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        },
      };
    } else if (listing.agreementType || listing.statusType || listing.estamp) {
      if (listing.agreementType) {
        filterOptions["typeOfAgreement"] = {
          equals: listing.agreementType,
        };
      }
      if (listing.statusType) {
        filterOptions["status"] = {
          equals: listing.statusType,
        };
      }
      if (listing.estamp) {
        filterOptions["agreementType"] = {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        };
      }
    }
    if (listing.location) {
      filterOptions["location"] = listing.location;
    }

    payload["filterOptions"] = filterOptions;

    setAgreementPayload(payload);
    const response = await apiWrapWithErrorWithData(
      getAgreementsInSignedList({ ...payload })
    );
    if (response?.success && response?.agreements) {
      setListing({
        ...listing,
        list: response?.agreements,
        agreementsCount: response.agreementsCount,
        loaded: true,
      });
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  const data = listing?.list;

  useEffect(() => {
    getAgreementTypes();
  }, []);

  useEffect(() => {
    if (listing.agreementType || listing.statusType || listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.startDate && listing.endDate) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else {
      if (listing.startDate === null && listing.endDate === null) {
        fetchAgreementsList({});
      }
    }
  }, [listing.sortBy, listing.sortDirection]);

  useEffect(() => {
    fetchAgreementsList({});
  }, [uiConfigs.page]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    const url =
      process.env.PUBLIC_URL + `/app/agreements_manager/agreements/details/${agreementId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getAgreementsSigningCsvDownload({ ...agreementPayload })
      );
      if (response.success) {
        if (response?.agreements.length > 0) {
          const columnHeaders = Object.keys(response.agreements[0]);
          csvDownload({
            data: response.agreements,
            headers: columnHeaders,
            name: "agreement",
          });
        } else {
          showNotification({
            title: "Agreement csv download",
            message: "No agreement available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Agreement csv download",
        message: "Could not load agreement csv",
        color: "red",
      });
    }
  };
  

  const clearHandler = async () => {
    try {
      setUiConfigs({
        ...uiConfigs,
        page: 1,
        loading: loadingStates.LOADING,
      });

      const payload = {
        page: 1,
        sortByOptions: {
          [listing.sortBy]: listing.sortDirection,
        },
        filterOptions: {},
      };

      setAgreementPayload(payload);
      const response = await apiWrapWithErrorWithData(
        getAgreementsInSignedList({ ...payload })
      );

      if (response?.success && response?.agreements) {
        setListing({
          ...listing,
          searchString: "",
          agreementType: "",
          statusType: null,
          estamp: "",
          location: null,
          startDate: null,
          endDate: null,
          list: response?.agreements,
          agreementsCount: response.agreementsCount,
          loaded: true,
          estamp: null,
        });
      }

      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } catch (error) {
      console.log("Agreement list fetching error: ", error);
    }
  };
  const[year, setYear] = useState(2024);
  const [filters, setFilter] = useState({
    typeOfAgreement: null,
    mapBusinessunit: null,
  });
  const [pageSize, setPageSize]= useState(10);
  const [reviewAgreementpage, setReviewAgreementpage] = useState(1);
  let serialNumber = (reviewAgreementpage - 1) * pageSize + 1;
  const [configs, setConfigs] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const  filtersForData = await Object.fromEntries(
          Object.entries(filters).filter(([key, value]) => value !== null)
        );
        const response = await apiWrapWithErrorWithData(getAgreementsInSignedList({ page: reviewAgreementpage, pageSize: pageSize, filterOptions: filtersForData , year: year}));          
        if (response) {
          setConfigs({ loading: loadingStates.NO_ACTIVE_REQUEST, data: response });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [reviewAgreementpage, pageSize, filters, year]);

  return (
    <>
    {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&(<>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            textTransform: "lowercase",
            fontFamily: "Lexend",
            whiteSpace: "nowrap",
            paddingBottom: "13px",
            position: "relative",
            color: "black",
            // margin: "0 auto"
          }}
        >
          Agreements Signed
        </h1>

        <HamburgerMenuAgreements />
      </div>
      <div className="flex flex-row flex-wrap w-full">
        <AgreementAnalytics1 filter={filters} setFilter={setFilter} configs={configs} />
      </div>
      <div className="flex">
        <AgreementGraph year={year} setYear={setYear}  configs={configs} />
        <AgreementGraph1  configs={configs} />
      </div>

      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>signed agreements list</Text>
        </div>
      </div>
      </>)}
      
      {configs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
        configs.data?.agreements && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>sr no.</th>
                  <th>title</th>
                  <th>businesss unit</th>
                  <th>type</th>
                  <th>second party</th>
                  <th>start date</th>
                  <th>end date</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {configs.data.agreements.map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{`Agreement-${row.id}`}</td>
                      <td>{row.agreementData.json.location}</td>
                      <td>
                        {row.agreementData.json.typeOfAgreement}
                      </td>
                      <td>
                        {/* <UserAvatarView {...row.createdBy} /> */}
                        {row.agreementData.json.secondParty.name|| "NA"}
                      </td>
                      <td>{row.contractStartDate?.slice(0,10) || "-"}</td>
                      <td> {row.contractEndDate?.slice(0,10) }</td>
                      <td style={{textAlign: "center"}}>
                        {(row.relationshipType !== "Parent") ? 
                        <div className="flex flex-row ">
                          <ActionIcon
                            onClick={() => {
                              handlePlusClick(row.relationshipWith);
                            }}
                            color="white"
                          >
                            <Plus size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div> : <span className="mr-2">{"--"}</span>}
                      </td>
                      <td>
                        <div className="flex flex-row mr-4">
                          <ActionIcon
                            onClick={() => {
                              onViewClick(row.id);
                            }}
                            color="white"
                          >
                            <Eye size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      </td>
                      
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
      !configs.data.agreements?.length ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {/* </div> */}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST && configs.data?.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={reviewAgreementpage}
            onChange={(page) => {
              setReviewAgreementpage(page);
            }}
            total={Math.ceil(configs.data?.agreementsCount / pageSize)}
          />
          <Text className="mx-4">select page size</Text>
          <Select
          className="w-20"
          data={["10","20","50"]} 
          value={pageSize+""}
          onChange={(val)=> setPageSize(parseInt(val))}
          />
        </div>
      ) : null}
      
    </>
  );
};

export default AgreementsSigned;
